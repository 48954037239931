.restaurant-profile-main-container {
    height: auto;
    padding-bottom: 1px;
}

.restaurant-profile-image-container {
    z-index: 2;
    position: relative;
}

.restaurant-profile-image {
    width: 100%;
    height: 460px;
    object-fit: cover;
}

.restaurant-profile-image-save-button-container {
    display: flex;
    position: relative;
    z-index: 1;
}

.restaurant-profile-save-restaurant-icon {
    height: 24px;
    width: 19px;
    margin-right: 11px;
}

.restaurant-profile-saved-restaurant-icon {
    height: 23px;
    width: 17.5px;
    margin-right: 13px;
}

.restaurant-profile-save-restaurant-button {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 235px;
    height: 3rem;
    width: 230px;
    background-color: white;
    border-radius: 4px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.restaurant-profile-save-restaurant-button:hover {
    border: solid;
    border-color: red;
    cursor: pointer;
    background-color: rgb(253, 253, 253);
}

.restaurant-profile-save-restaurant-text {
    font-size: 0.88rem;
    color: rgb(21, 21, 21);
    font-weight: 400;
}

.overflow-into-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -45px;
    margin-left: 2.5px;
    position: sticky;
    z-index: 2;
}

.restaurant-profile-details-tab-and-border {
    display: flex;
    flex-direction: column;
    margin-left: -373px;
}

.restaurant-profile-details-tabs {
    background-color: white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: sticky;
    width: 620px;
    height: 54.5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: rgb(111, 115, 123);
    font-size: 0.85rem;
    font-weight: 400;
    margin-top: -9.5px;
}

.restaurant-profile-details-tabs:hover {
    cursor: text;
}

.restaurant-profile-details-tab-border-bottom {
    border-bottom: 1px solid;
    border-color: rgb(226, 226, 226);
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
}

.restaurant-profile-details-tab-underscore {
    width: 58px;
    border-bottom: 1.5px solid;
    border-color: rgb(209, 4, 4);
    margin-left: 0px;
    position: absolute;
}

.restaurant-profile-upper {
    object-fit: cover;
    height: 460px;
}

.restaurant-profile-lower {
    height: auto;
    margin-left: 30px;
}

.restaurant-profile-details-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:center;
    height: auto;
}

.restaurant-profile-description-container {
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: solid;
    border-width: thin;
    padding-bottom: 10px;
    border-color: rgb(226, 226, 226);
    margin-right: 5px;
    margin-left: 10px;
}

.restaurant-profile-description {
    margin-left: -10px;
}

.restaurant-profile-left {
    width: 620px;
    margin-right: 50px;
    height: auto;
}

.restaurant-profile-reviews-clickable:hover {
    cursor: pointer;
}

.restaurant-profile-right {
    width: 350px;
    min-height: 800px;
    margin-top: -55px;
}

.restaurant-reviews-container {
    border-color: rgb(216, 214, 214);
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 40px;
    margin-right: 8px;
    margin-left: 10px;
}

.restaurant-profile-name {
    font-size: 1.6rem;
    font-weight: 700;
    color: #2d333f;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    line-height: 3.5rem;
    border-bottom: 1px solid;
    border-color: rgb(216, 217, 219);
}

.restaurant-profile-general-info {
    border-color: rgb(224, 224, 224);
    border-width: thin;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.profile-star {
    font-size: 0.9rem;
}

.restaurant-profile-general-info-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.9rem;
}

.restaurant-profile-general-info-bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.additional-info-container {
    margin-top: 380px;
    margin-left: 5px;
    width: 310px;
    height: auto;
    margin-bottom: 200px;
}

/* Spacing */
.space-to-left-11 {
    margin-left: 17px;
    color: rgb(214, 4, 4);
}

.space-to-left-11:hover{
    color: rgb(142, 2, 2);
}

.space-to-left-12 {
    margin-left: 35px;
}

.space-to-left-12:hover {
    color: rgb(142, 2, 2);
}

.space-to-left-21 {
    margin-left: 15px;
}

.space-to-left-22 {
    margin-left: 15px;
}

.space-to-left-23 {
    margin-left: 15px;
}

.space-to-left-24 {
    margin-left: 9px;
}

.space-to-left-25 {
    margin-left: 8px;
}
.profile-avg-rating-num {
    margin-left: 2px;
}

/* Icons */
.profile-reviews-icon {
    height: 20px;
    margin-right: 1px;
}

.profile-cost-icon {
    height: 19px;
}

.profile-cuisine-icon {
    height: 23px;
}

.reviews-icon-div {
    margin-top: 6px;
    margin-right: 2px;
}

.cost-icon-div {
    margin-right: 2px;
    margin-top: 4px;
}

.cost-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.review-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cuisine-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cuisine-icon-div {
    margin-right: 4px;
    margin-top: 2px;
}

.top-tags1 {
    font-weight: 600;
    color:rgb(45, 51, 63)
}

.top-tags2 {
    height: 25px;
    width: 80px;
    text-align: center;
    line-height: 24px;
    border: 1.4px solid;
    border-radius: 30px;
    font-size: 0.9rem;
    font-weight: 500;
    border-color:rgb(216, 217, 219)
}

.top-tags3 {
    height: 25px;
    width: 130px;
    text-align: center;
    line-height: 24px;
    border: 1.4px solid;
    border-radius: 30px;
    font-size: 0.9rem;
    font-weight: 500;
    border-color:rgb(216, 217, 219)

}

.top-tags4 {
    height: 25px;
    width: 120px;
    text-align: center;
    line-height: 24px;
    border: 1.4px solid;
    border-radius: 30px;
    font-size: 0.9rem;
    font-weight: 500;
    border-color:rgb(216, 217, 219)
}

.restaurant-profile-star {
    font-size: 1.2rem;
}