.cancel-reservation-modal-container {
    height: 210px;
    width: 425px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 30px;
}

.cancellation-heading {
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 15px;
}

.cancel-reservation-modal-time {
    margin-left: -15px;
    margin-top: -35px;
}

.reservation-cancellation-person-icon {
    height: 22px;
    width: 21px;
    margin-bottom: -4px;
}

.reservation-cancellation-person-icon-container {
    margin-right: 5px;
}

.reservation-cancellation-upcoming-reservations-icon {
    height: 32px;
    margin-top: 5px;
    margin-right: -3px;
}

.cancel-reservation-restaurant-name {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;

}

.cancel-reservation-modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-right: 10px;
}

.reservation-cancellation-upcoming-reservations-icon {
    margin-bottom: -10px;
}

.reservation-cancel-modal-cancel-button {
    height: 35px;
    width: 150px;
    border: none;
    border-radius: 5px;
    background-color: rgb(218, 54, 68);
    color: white;
    font-size: 0.83rem;
    font-weight: 600;
}

.reservation-cancel-modal-cancel-button:hover {
    cursor: pointer;
}

.reservation-cancel-modal-nevermind-button {
    height: 30px;
    border: none;
    background-color: white;
    color: rgb(218, 54, 68);
    font-size: 0.85rem;
    font-weight: 600;
    margin-right: 9px;
}

.cancel-reservation-modal-x {
    height: 16px;
    width: 16px;
    margin-left: 437px;
    margin-bottom: -5px;
}

.cancel-reservation-modal-x:hover {
    cursor: pointer;
}