.profile-drop-logout-button  {
    background-color: white;
    border: none;
    font-size: 1.05rem;
    font-weight: 400;
    padding-left: 15px;
    color: rgb(200, 9, 9);
    width: 305px;
    height: 30px;
    line-height: 1.6;
}

.profile-drop-logout-button:hover {
    background-color: #f7f7f7;
    color: rgb(80, 77, 77);
    cursor: pointer
}

/* .profile-drop-logout-div:hover {
    background-color: #f7f7f7;
    color: rgb(80, 77, 77);

} */