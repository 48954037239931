.add-review-form-outer-container {
    height: auto;
    width: 550px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding-bottom: 50px;
}

.create-review-form {
    display: flex;
    flex-direction: column;
}

.review-inputs {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.review-text-input {
    height: 80px;
    margin-bottom: 10px;
    border-radius: 6px;
    border: 1px solid rgb(164, 163, 163);
    width: calc(100% - 16px);
    font-size: large;
    padding-left: 5px;
    padding-top: 5px;
    overflow-y: scroll;
}

.rating-input {
    width: calc(100% - 18px);
    height: 40px;
    border-radius: 6px;
    font-size: large;
    padding-left: 8px;
    border: 1px solid rgb(164, 163, 163);
    margin-bottom: 20px;
}

.review-form-heading {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px;
}

.review-submit-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.submit-review-button {
    width: 200px;
    height: 45px;
    border-radius: 4px;
    border: none;
    background-color: rgb(218, 54, 68);
    color: white;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: -40px;
}

.submit-review-button:hover {
    cursor: pointer;
}

.review-validation-errors {
    color: red;
    font-size: 0.9rem;
}

.update-review-validation-errors {
    color: red;
    font-size: 0.9rem;
}

.create-review-rating-container {
    position: relative;
}

.create-review-select {
    width: 542px;
    height: 50px;
    font-size: 1.5rem;
    margin-top: 20px;
    border-radius: 6px;
    border-color: rgb(164, 163, 163);
    appearance: none;
    padding-left: 5px;
    z-index: 7;
    background-color: rgba(0, 0, 0, 0);
    position: relative;
}

.create-review-select:hover {
    cursor: pointer;
}

.create-review-down-caret {
    height: 15px;
    position: absolute;
    z-index: 5;
    right: 0;
    margin-top: 38px;
    margin-right: 15px;
}