.login-form {
    height: 355px;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 5px;
}

.sign-in-input {
    width: calc(100% - 16px);
    height: 40px;
    border-radius: 6px;
    border-color: gray;
    font-size: large;
    margin-top: 12px;
    padding-left: 8px;
    padding-right: 8px;
    border: 1px solid rgb(164, 163, 163);
}

.sign-in-form-button1 {
    background-color: rgb(218, 54, 68);
    color: white;
    height: 50px;
    width: 100%;
    border-radius: 6px;
    font-size: 1em;
    border: none;
    margin-top: 35px;
}

.sign-in-form-button2 {
    background-color: rgb(218, 54, 68);
    color: white;
    height: 50px;
    width: 100%;
    border-radius: 6px;
    font-size: 1em;
    border: none;
    margin-top: 15px;
}

.sign-in-form-button1:hover {
    cursor: pointer;
}

.sign-in-form-button2:hover {
    cursor: pointer;
}

.sign-in-form-error-messages {
    font-size: 0.8rem;
    font-weight: 500;
    color: red;
    margin-top: -20px;
}

