.footer-container {
    color: rgb(222, 217, 217);
    background-color: rgb(45, 51, 63);
    font-weight: 300;
    font-size: 0.9rem;
    padding-top: 50px;
    height: 400px;
}

.linkedin-link {
    color: rgb(255, 255, 255);
    font-size: 2rem;
}

.github-link {
    color: rgb(255, 255, 255);
    font-size: 2rem;
}

.angel-list-link {
    color: rgb(255, 255, 255);
    height: 20px;
}

.angel-list-icon {
    height: 70px;
    margin-left: -20px;
    margin-bottom: -23px;
    color: rgb(169, 41, 41);
}

.ayrbnb-link {
    text-decoration: none;
    color: rgb(222, 217, 217);
    margin-left: 20px;
    display: flex;
    align-items: center;
    line-height: 2.44;
}

.codebunny-link {
    text-decoration: none;
    color: rgb(222, 217, 217);
    margin-left: 20px;
    display: flex;
    align-items: center;
    line-height: 2.43;
}

.opentable-link {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.footer-top {
    display: flex;
    flex-direction: row;
    margin-left: 340px;
}

.footer-top-left {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.footer-tech-stack {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: -40px;
}

.footer-below-tech-stack {
    display: flex;
    flex-direction: column;
}

.footer-below-tech-stack>div {
    margin-top: 7px;
}

.footer-tech-stack-text {
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
}

.footer-hosting {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 80px;
}

.footer-hosting-text {
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
}

.footer-below-hosting {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.footer-below-hosting>div {
    margin-top: 7px;
}

.footer-ides {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 73px;
}

.footer-ides-text {
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
}

.footer-below-ides {
    display: flex;
    flex-direction: column;
}

.footer-below-ides>div {
    margin-top: 7px;
}

.footer-other-sites {
    display: flex;
    flex-direction: column;
    margin-left: 75px;
}

.footer-other-site {
    background-color: rgb(111, 115, 123);
    height: 35px;
    width: 210px;
    border-radius: 4px;
    font-weight: 600;
    color: white;
    margin-bottom: 10px;
}

.footer-other-site:hover {
    cursor: pointer;
    background-color: rgb(117, 123, 133);
}

.footer-right-arrow {
    height: 20px;
}

.ayrbnb-arrow {
    margin-left: 26px;
}

.codebunny-arrow {
    margin-left: 14px;
}

.footer-other-sites-text {
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
}

.footer-below-other-sites {
    display: flex;
    flex-direction: column;
}

.footer-below-other-sites>div {
    margin-top: 5px;
}

.footer-connect {
    margin-left: 50px;
}

.footer-connect-with-me-text {
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin-left: 12px;
}

.footer-below-connect {
    display: flex;
    flex-direction: row;
}

.footer-connect-with-me-text {
    margin-bottom: 13px;
}

.footer-links>a {
    margin-left: 10px;
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    margin-left: 298px;
    margin-top: 90px;
}

.footer-terms {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -14px;
}

.footer-terms>div {
    margin-left: 15px;
}

.footer-copyright {
    margin-top: 30px;
}

.footer-name-text {
    color: rgb(255, 255, 255);
    font-weight: 600;
}

.footer-name-text-underline {
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-decoration: underline;
}