.upcoming-reservations-menu-container {
    position: absolute;
    background-color: white;
    height: 280px;
    width: 330px;
    display: flex;
    margin-left: -255px;
    margin-top: 330px;
    border: 1px solid;
    border-color: rgb(235, 235, 235);
    border-radius: 4px;
    z-index: 9;
}

.next-reservation-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:flex-start;
    margin-left: 2px;
}

.upcoming-reservations-menu-heading {
    font-size: 1rem;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 2px;
}

.upcoming-reservation-menu-name {
    margin-left: 5px;
    font-size: 0.88rem;
    font-weight: 600;
    margin-bottom: 11px;
}

.upcoming-reservation-menu-icon-name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.upcoming-reservation-menu-date-details {
    margin-left: 45px;
    margin-top: -25px;
    font-size: 0.88rem;
    margin-bottom: -10px;
}

.upcoming-reservation-menu-person-icon {
    height: 20px;
    width: 17px;
}

.upcoming-reservations-clock-icon {
    height: 17.5px;
    width: 19px;
    margin-left: -1.4px;
    margin-top: 1.5px;
    margin-right: 2px;
}

.upcoming-reservation-menu-party-size {
    display: flex;
    flex-direction: row;
    margin-top: -2px;
    margin-bottom: -1px;
}

.upcoming-reservation-menu-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -3px;
}

.upcoming-reservation-party-size-text {
    margin-left: 2px;
    margin-top: 2px;
}

.upcoming-reservation-menu-icon {
    height: 27px;
    width: 28px;
    margin-left: -6px;
    margin-top: -1px;
}

.upcoming-reservation-menu-time {
    display: flex;
}

.upcoming-reservation-menu-time-details {
    margin-left: -2px;
    margin-top: -6px;
}

.upcoming-reservation-reservation-time {
    margin-right: 3px;
}


.upcoming-restaurant-icon {
    height: 35px;
    margin-left: 3px;
}

.upcoming-reservations-false-container {
    margin-bottom: -400px;
    height: 100px;
}

.upcoming-view-all-reservations {
    cursor: pointer;
}

.upcoming-reservation-menu-options {
    margin-left: 38px;
    margin-top: -10px;
}

.upcoming-reservation-button {
    background-color: white;
    border: none;
    font-size: 0.85rem;
    color: rgb(37, 127, 158);
    font-weight: 500;
}

.upcoming-reservation-button-no-pointer {
    background-color: white;
    border: none;
    font-size: 0.85rem;
    color: rgb(37, 127, 158);
    font-weight: 500;
}

.upcoming-reservation-button-no-pointer:hover {
    cursor: not-allowed;
}

.upcoming-reservation-button:hover {
    cursor: pointer;
}

.upcoming-reservation-menu-dot {
    color: rgb(202, 202, 202);
    font-size: large;
}

.upcoming-reservation-menu-cancel-button {
    margin-left: 43px;
    margin-top: -14px;
    font-size: 0.86rem;
    color: rgb(218, 54, 68);
    font-weight: 500;
}

.upcoming-reservation-menu-cancel-button:hover {
    cursor: pointer;
}

.upcoming-reservation-view-all-reservations {
    margin-left: 20px;
    font-size: 0.82rem;
    color: rgb(218, 54, 68);
}

.upcoming-reservation-view-all-reservations-container {
    height: 20px;
    width: 250px;
}

.upcoming-reservation-view-all-reservations-container:hover {
    cursor: pointer;
}

.upcoming-reservation-menu-border-line {
    border-bottom: 1px solid;
    width: 307px;
    padding-top: 15px;
    border-color: rgb(226, 225, 225);
    margin-left: 5px;

}

.upcoming-reservation-menu-none {
    position: absolute;
    background-color: white;
    height: 90px;
    width: 330px;
    display: flex;
    flex-direction: column;
    margin-left: -258px;
    margin-top: 140px;
    border: 1px solid;
    border-color: rgb(235, 235, 235);
    border-radius: 4px;
    z-index: 5;
}

.upcoming-reservations-menu-none-heading {
    font-size: 1rem;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 20px;
}

.upcoming-reservations-menu-none-message {
    font-size: 0.9rem;
    font-weight: 400;
    color: rgb(62, 61, 61);
    margin-left: 10px;
    margin-top: 20px;
}