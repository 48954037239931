.nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    border-color: rgb(236, 235, 235);
    height: 53px;
}

.logo {
    width: 170px;
    height: 34px;
    margin-left: 27px;
    padding-right: 8px;
    margin-top: 5px;
}

.nav-bar-logo-location-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-bar-logo {
    border-right: solid;
    border-color: rgb(236, 235, 235);
    border-width: thin;
}

.notifications-icon {
    margin-right: 15px;
}

.above-nav {
    height: 27px;
    background-color: rgb(241, 242, 244);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 0.85rem;
    color: rgb(84, 84, 84);
    line-height: 1.8;
    font-weight: 400;
}

.above-nav-en {
    margin-right: 19px;
}

.above-nav-faqs {
    margin-right: 50px;
}

.above-nav-mobile {
    margin-right: 20px;
}

.above-nav-for-businesses {
    margin-right: 18px;
}

.above-nav-down-caret {
    height: 10px;
    width: 14px;
    margin-left: 2px;
}

.nav-bar-location-city-text {
    font-size: 1.01rem;
    color: rgb(95, 95, 95);
    font-weight: 400;
    margin-left: 4px;
    margin-top: 2px;
}

.nav-bar-location-city-text:hover {
    cursor: not-allowed;
}

.above-nav-img {
    height: 26px;
    width: 285px;
    margin-right: 10px;
}

.above-nav-img:hover {
    cursor: text;
}

.location-icon {
    height: 27px;
    margin-left: 12px;
    margin-top: 3px;
}

.down-carrot-icon {
    height: 12px;
    margin-left: 6px;
    margin-top: 8px;
}

.down-carrot-icon:hover {
    cursor: not-allowed;
}