.reservation-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    background-color: white;
    border-radius: 4px;
    border: 1px solid rgb(229, 229, 229);
    box-shadow: 0 2px 4px #2d333f33;
}

.reservation-form-heading {
    font-size: 1.1rem;
    height: 50px;
    border-bottom: 1px solid;
    margin-left: 16px;
    margin-right: 17px;
    margin-top: 1px;
    padding-bottom: -3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-color: rgb(222, 221, 221);
}

.reservation-form-container {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 9;
}

.reservation-heading-font {
    font-weight: 600;
    color: rgb(50, 49, 49);
}

.reservation-party-size-text {
    font-size: 0.9rem;
    font-weight: 400;
    color: rgb(0, 0, 0);
    margin-top: 18px;
    margin-left: 15px;
    margin-bottom: 5px;
}

.reservation-party-size-select {
    width: 295px;
    margin-right: 7px;
    margin-top: 9px !important;
    margin-bottom: 1px;
    border: none;
    font-size: 0.9rem;
    font-weight: 300;
    color: rgb(0, 0, 0);
    appearance: none;
    z-index: 7;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.reservation-party-size-select:hover {
    cursor: pointer;
}

.reservation-down-caret {
    height: 12px;
    z-index: 5;
    position: absolute;
    right: 0;
    margin-right: 7px;
    margin-bottom: -14px;
}

.reservation-party-size-border-bottom {
    border-bottom: 1px solid;
    border-color: rgb(222, 221, 221);
    margin-bottom: 10px;
    padding-bottom: 13px;
    margin-left: 15px;
    margin-right: 14px;
    width: 290px;
}

.reservation-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.reservation-date-down-caret {
    height: 12px;
    position: absolute;
    z-index: 5;
    right: 0;
    margin-top: 5px;
}

.reservation-date-time-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.reservation-date-input-border {
    border-bottom: 1px solid;
    border-color: rgb(222, 221, 221);
    margin-bottom: 19px;
    width: 140px;
    margin-left: 8px;
}

.reservation-date-input {
    width: 140px;
    border: none;
    margin-top: 15px;
    font-size: 0.95rem;
    font-weight: 300;
    color: rgb(0, 0, 0);
    -webkit-text-fill-color: black;
    margin-bottom: 9px;
    z-index: 7;
    background-color: rgba(0, 0, 0, 0);
    margin-left: -1px;
}

/* .reservation-date-input::-webkit-calendar-picker-indicator {
    display: none;
} */

.reservation-date-text {
    font-size: 0.9rem;
    color: rgb(1, 1, 1);
    margin-left: 1px;
    font-weight: 400;
}

.reservation-date-input:hover {
    cursor: pointer;
}

.reservation-time-select:hover {
    cursor: pointer;
}

.reservation-time-input-border {
    border-bottom: 1px solid;
    border-color: rgb(222, 221, 221);
    margin-right: 10px;
    margin-bottom: 20px;
    margin-left: 15px;
    width: 125px;
}

.reservation-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.reservation-time-down-caret {
    height: 12px;
    position: absolute;
    z-index: 5;
    right: 0;
    margin-top: 7px;
}

.reservation-time-select {
    width: 128px;
    font-size: 0.95rem;
    font-weight: 300;
    color: rgb(0, 0, 0);
    border: none;
    margin-left: 2px;
    margin-top: 15px;
    margin-bottom: 9px;
    appearance: none;
    z-index: 7;
    background-color: rgba(0, 0, 0, 0);

}

.reservation-time-text {
    font-size: 0.9rem;
    color: rgb(0, 0, 0);
    margin-left: 2px;
    font-weight: 400;
}

.reservation-reserve-table-button {
    width: 295px;
    height: 46px;
    margin-left: 12px;
    margin-top: -3px;
    border-radius: 4px;
    border: none;
    background-color: rgb(218, 54, 68);
    font-size: 1rem;
    color: white;
    font-weight: 600;
}

.reservation-reserve-table-button:hover {
    cursor: pointer;
}

.reservation-booking-symbol {
    height: 15px;
    width: 20px;
    margin-top: 2px;
}

.reservation-booking-total-num {
    display: flex;
    flex-direction: row;
    margin-top: 18px;
    margin-left: 13px;
    padding-bottom: 18px;
}

.reservation-booking-text {
    font-size: 0.9rem;
}

.reservation-booking-align {
    display: flex;
    flex-direction: row;
}

.reservation-errors-container {
    margin-left: 16px;
    margin-top: 6px;
    margin-bottom: -10px;
}

.reservation-errors {
    color: red;
    font-size: 0.9rem;
}