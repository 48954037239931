#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  #modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(76, 75, 75, 0.25);
  }
  
  #modal-content {
    position: absolute;
    background-color:white;
    padding: 1em;
    /* border-radius: 1em; */
    border-radius: 4px;
    max-height: 80vh;
    overflow-y: auto;
  }

