* {
    font-family: 'Source Sans Pro', sans-serif;
}

dark-font {
    color: rgb(45, 51, 63)
}


@media screen and (min-width:1600px) {
    body {
        width: 1600px;
        margin: 0 auto;
        background-color: rgb(241, 242, 244);
    }

    #root {
        background-color: white;
    }
}

body {
    max-width: 1600px;
}