.reviews-container {
    border-bottom: 1px solid;
    border-color: rgb(223, 223, 223);
    display: flex;
    flex-direction: row;
    height: 190px;
    justify-content: space-around;
    width: 615px;
    margin-left: -17px;
    padding-top: 20px;
}

.reviews-main-outer-container {
    height: auto;
}

.reviews-outer-container {
    height: auto;
}

.restaurant-reviews-count-text {
    margin-left: -15px;
}

.reviews-upper-border {
    border-bottom: 1px solid;
    width: 608px;
    margin-left: -15px;
    margin-top: -20px;
    border-bottom-color: rgb(195, 195, 195);
}

.review-container-left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    width: 180px;
    margin-top: 17px;
    margin-left: -30px;
}

.review-container-right {
    width: 100%;
    padding-top: 5px;
    margin-top: 15px;
}

.reviewer-initials-circle {
    font-weight: 300;
    color: white;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    background-color: rgb(182, 182, 232);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.reviwer-initials {
    margin-right: -4px;
}

.reviewer-name {
    margin-top: 8px;
}

.reviewer-initials-and-options {
    height: 100px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.review {
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(88, 88, 88);
    height: 58px;
}

.review-rating-stars {
    margin-top: 7px;
    margin-left: 3px;
}

.review-star {
    font-size: 1.1rem;
}

.update-review-button {
    height: 20px;
    width: 80px;
    font-size: 0.7rem;
    border: none;
    background-color: rgb(218, 54, 68);
    color: white;
    border-radius: 4px;
}

.update-review-button:hover {
    cursor: pointer;
}

.delete-review-button {
    height: 20px;
    width: 80px;
    font-size: 0.7rem;
    border: none;
    background-color: rgb(218, 54, 68);
    color: white;
    border-radius: 4px;
}

.delete-review-button:hover {
    cursor: pointer;
}

.leave-review-button {
    margin-top: 10px;
    height: 25px;
    font-size: 0.8rem;
    border: none;
    background-color: rgb(218, 54, 68);
    color: white;
    border-radius: 4px;
    margin-bottom: 20px;
}

.leave-review-button:hover {
    cursor: pointer;
}