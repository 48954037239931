.modify-reservation-container {
    display: flex;
    flex-direction: column;
    margin-left: 260px;
    height: auto;
    width: 640px;
    margin-top: 20px;
    padding-bottom: 80px;
}

.modify-reservation-header {
    font-size: 0.95rem;
    font-weight: 600;
}

.modify-reservation-preview-image {
    height: 65px;
    width: 65px;
    border-radius: 4px;
    object-fit: cover;
}

.modify-reservation-reservation-time {
    margin-top: 4px;
}

.modify-reservation-name-time {
    display: flex;
    flex-direction: column;
}

.modify-reservation-image-and-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 28px;
    height: 50px;
    padding-bottom: 20px;
}

.modify-reservation-top {
    border-bottom: 1.2px solid;
    margin-top: 20px;
    border-color: rgb(226, 226, 226);
    width: 622px;
}

.modify-reservation-bottom {
    margin-top: 20px;
}

.modify-reservation-form {
    display: flex;
    flex-direction: row;
}

.modify-reservation-person-icon {
    height: 23px;
    width: 20px;
}

.modify-reservation-upcoming-reservations-icon {
    height: 30px;
    width: 35px;
}

.modify-reservation-restaurant-name {
    margin-left: 14px;
    margin-top: 2px;
}

.modify-reservation-restaurant-name-text {
    font-size: 1.42rem;
    font-weight: 600;
}

.modify-reservation-reservation-time {
    display: flex;
}

.modify-reservation-current-reservation-date {
    margin-top: 4px;
    color: rgb(54, 53, 53);
    font-size: 1.05rem;
}

.modify-reservation-clock-icon {
    height: 21px;
    width: 24px;
}

.modify-reservation-reservation-clock-icon-container {
    margin-left: 30px;
    margin-top: 5px;
}

.modify-reservation-reservation-time-container {
    margin-left: -12px;
    margin-top: 5px;
}

.modify-reservation-reservation-time {
    margin-left: 20px;
    width: 500px;
}

.modify-reservation-current-reservation-time {
    margin-top: 4px;
    margin-left: 7px;
    color: rgb(54, 53, 53);
    font-weight: 400;
    font-size: 1.05rem;
}

.modify-reservation-person-icon-container {
    margin-top: 3.5px;
    margin-left: 27px;
}

.modify-reservation-current-party-size {
    margin-left: 10px;
    margin-top: 3px;
    color: rgb(54, 53, 53);
    font-weight: 500;
    font-size: 1.05rem;
}

.modify-reservation-bottom-header {
    font-size: 1rem;
    font-weight: 500;
}

.modify-reservation-errors-container {
    color: red;
    font-size: 0.9rem;
}

.modify-reservation-lower {
    display: flex;
}

.modify-reservation-form-container {
    height: 50px;
    width: 460px;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 4px;
    margin-top: 10px;
}

.modify-reservation-date-container {
    display: flex;
    align-items: center;
    border-right: 1px solid rgb(207, 207, 207);
    width: 169px;
    position: relative;
}

.modify-reservation-new-date-icon-container {
    display: flex;
    margin-left: 9px;
}

.modify-reservation-new-date-icon {
    height: 21px;
}

.modify-reservation-new-reservation-date-select {
    border: none;
    font-size: 1.08rem;
    width: 120px;
    margin-left: 10px;
    appearance: none;
    z-index: 7;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
}

.modify-reservation-new-reservation-date-select:hover {
    cursor: pointer;
}

.modify-reservation-new-reservation-date-down-caret {
    height: 12px;
    z-index: 5px;
    position: absolute;
    right: 0;
    margin-right: 11px;
    margin-top: 6px;
}

.modify-reservation-time-container {
    display: flex;
    align-items: center;
    border-right: 1px solid rgb(207, 207, 207);
    width: 117px;
}

.modify-reservation-new-time-icon-container {
    display: flex;
    margin-left: -12px;
    position: relative;
}

.modify-reservation-new-time-icon {
    height: 21px;
}

.modify-reservation-new-time-select {
    border: none;
    font-size: 1.08rem;
    width: 90px;
    margin-left: 8px;
    appearance: none;
    z-index: 7;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
}

.modify-reservation-new-time-select:hover {
    cursor: pointer;
}

.modify-reservation-new-reservation-time-down-caret {
    height: 12px;
    z-index: 5px;
    position: absolute;
    margin-left: 80px;
    margin-top: 6px;
}

.modify-reservation-party-size-container {
    display: flex;
    align-items: center;
    width: 130px;
}

.modify-reservation-new-party-size-icon-container {
    display: flex;
    margin-left: -11px;
    position: relative;
}

.modify-reservation-new-party-size-icon {
    height: 22px;
}

.modify-reservation-new-party-size-select {
    border: none;
    font-size: 1.08rem;
    margin-left: 6px;
    width: 105px;
    appearance: none;
    z-index: 7;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
}

.modify-reservation-new-party-size-select:hover {
    cursor: pointer;
}

.modify-reservation-new-reservation-party-size-down-caret {
    height: 12px;
    z-index: 5px;
    position: absolute;
    margin-left: 93px;
    margin-top: 6px;
}

.modify-reservation-submit-button-container {
    margin-top: 10px;
}

.modify-reservation-new-reservation-submit-button {
    height: 50px;
    width: 145px;
    border-radius: 4px;
    border: none;
    background-color: rgb(218, 54, 68);
    color: rgb(255, 255, 255);
    font-size: 0.97rem;
    font-weight: 600;
    margin-left: 16px;
}

.modify-reservation-new-reservation-submit-button:hover {
    cursor: pointer;
}