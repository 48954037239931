.navigation-menu {
    margin-right: 6px;
}

.nav-bar-menu-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sign-in-menu-items {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.profile-button-icon {
    height: 39px;
    width: 39px;
    margin-top: 4px;
    margin-right: 10px;
    margin-left: -2px;
}

.profile-button {
    border: none;
    background-color: white;
    cursor: pointer;
    margin-right: 15px;
    height: 48px;
    width: 40px;
}

.upcoming-reservations-menu-button {
    border: none;
    background-color: white;
    height: 30px;
    width: 35px;
    margin-bottom: 3px;
    margin-left: -12px;
    margin-right: 14px;
}

.upcoming-reservations-menu-button:hover {
    cursor: pointer;
}

.upcoming-reservations-icon {
    height: 31px;
    width: 36px;
    margin-left: -2px;
    margin-top: 0.5px;
}

.upcoming-reservations-notification-icon {
    height: 26px;
    width: 26px;
    margin-left: 4px;
    margin-top: 1px;
}

.notifications-icon {
    height: 22px;
    width: 20px;
    margin-right: 6px;
    margin-left: 1px;
    margin-top: 1px;
}

.notifications-icon:hover {
    cursor: not-allowed;
}

.line-break {
    height: 36px;
    width: 17px;
    margin-right: 15px;
}

.logged-in-search-button {
    height: 23px;
    width: 24px;
    padding-right: 20px;
    margin-top: 2px;
}

.logged-in-search-button:hover {
    cursor: not-allowed;
}

.sign-up-button {
    height: 35px;
    width: 76px;
    margin-right: 8px;
    border-radius: 3px;
    background-color: rgb(37, 127, 158);
    font-size: .9rem;
    font-weight: 300;
    color: rgb(255, 255, 255);
    border: rgb(201, 201, 201);
}

.sign-up-button:hover {
    cursor: pointer;
}

.sign-in-button:hover {
    cursor: pointer;
}

.sign-in-button {
    height: 35px;
    width: 76px;
    border-radius: 3px;
    font-size: .9rem;
    font-weight: 300;
    color: rgb(16, 16, 16);
    background-color: white;
    border: solid;
    border-width: thin;
    border-color: rgb(224, 222, 222);
    box-shadow: none;
}

.logged-out-search-button {
    height: 23px;
    width: 23px;
    padding-right: 15px;
    padding-left: 18px;
    padding-top: 4px;
}

.logged-out-search-button:hover {
    cursor: not-allowed;
}