.reservation-details-outer-container {
    height: 470px;
    display: flex;
    flex-direction: row;
    background-color: rgb(241, 242, 244);
}

.reservation-confirmation-details-left {
    width: 630px;
    background-color: white;
    border: 1.8px solid;
    border-color: rgb(228, 228, 228);
    height: 300px;
    margin-left: 260px;
    margin-top: 35px;
    display: flex;
    flex-direction: column;
}

.reservation-confirmation-details-left-upper {
    display: flex;
}

.reservation-confirmation-details-right {
    background-color: white;
    width: 300px;
    height: 200px;
    margin-left: 42px;
    margin-top: 35px;
}

.user-info-dining-history-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.user-info-details-container {
    height: 200px;
}

.edit-reservation-line-break {
    height: 20px;
}

.reservation-restaurant-image {
    margin-left: 28px;
    margin-top: 35px;
}

.reservation-confirmation-preview-img {
    height: 125px;
    width: 125px;
    border-radius: 4px;
}

.reservation-restaurant-name-options {
    margin-top: 37px;
    margin-left: 20px;
}

.reservation-confirm-name-text {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3px;
}

.reservation-confirm-name-text:hover {
    cursor: pointer;
}

.reservation-confirm-past-name-text {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 3px;
    width: 280px;
}

.reservation-confirm-past-name-text:hover {
    cursor: pointer;
}

.reservation-confirm-check-mark {
    height: 22px;
    margin-top: 5px;
    margin-bottom: -5px;
}

.reservation-confirm-confirmed-text {
    font-size: 1rem;
    margin-left: 4px;
}

.reservation-confirm-person-icon {
    height: 22px;
    width: 22px;
    margin-top: -1px;
}

.reservation-confirm-party-size-time-container {
    display: flex;
    margin-top: 12px;
    margin-bottom: -5px;
}

.reservation-confirm-past-party-size-time-container {
    display: flex;
    margin-top: 12px;
    margin-bottom: -5px;
    width: 355px;
    height: 40px;
}

.reservation-confirm-party-size-text {
    font-size: 1rem;
    color: rgb(34, 34, 34);
}

.reservation-confirm-upcoming-icon {
    height: 30px;
    width: 32px;
    margin-top: -3.5px;
    margin-bottom: 8px;
    margin-left: -3px;
}

.reservation-confirmation-reservation-date-time {
    margin-top: 1px;
}

.edit-reservation-line-break {
    height: 25px;
    width: 5px;
    margin-top: 1px;
    margin-bottom: -7px;
}
.reservation-confirm-buttons {
    border: none;
    background-color: white;
    color: rgb(218, 54, 68);
    font-size: 1rem;
}

.reservation-confirm-buttons:hover {
    cursor: pointer;
}

.add-to-calendar:hover {
    cursor: not-allowed;
}

.reservation-confirm-space-to-left {
    margin-left: -6px;
    margin-top: -2px;
}

.reservation-confirmation-details-right {
    height: 175px;
    width: 300px;
}

.user-info-details-container {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    margin-top: 5px;
}

.reservation-confirm-location-icon {
    height: 28px;
    width: 25px;
}

.reservation-confirm-user-person-icon {
    height: 25px;
    width: 24px;
    margin-top: 2px;
}

.reservation-confirmation-person-name {
    display: flex;
    flex-direction: row;
    margin-left: 25px;
    margin-top: 20px;
}

.reservation-confirm-person-name-text {
    font-size: 1.6rem;
    font-weight: 500;
    margin-top: -2px;
    margin-left: 4px;
}

.reservation-confirm-joined-in-date {
    font-size: 1rem;
    margin-left: 53px;
    margin-top: 2px;
    color: rgb(121, 120, 120);
}

.reservation-confirm-location-icon-city {
    display: flex;
    flex-direction: row;
    margin-left: 25px;
    margin-top: 15px;
}

.reservation-confirm-city-text {
    font-size: 1.05rem;
    color: rgb(121, 120, 120);
    margin-left: 5px;
    margin-top: 3px;
}

.reservation-confirm-num-reviews-container {
    display: flex;
    margin-top: 5px;
    margin-left: 27px;

}

.reservation-confirm-num-reviews-icon {
    height: 21px;
    width: 23px;
    margin-right: 5px;
}

.reservation-confirm-num-reviews-text {
    font-size: 1rem;
    color: rgb(121, 120, 120);

}

.reservation-restaurant-name-book-again-container {
    display: flex;
}

.reservation-confirm-past-book-again-button {
    height: 50px;
    width: 138px;
    border: none;
    background-color: rgb(218, 54, 68);
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    margin-left: 10px;
}

.reservation-confirm-past-book-again-button:hover {
    cursor: pointer;
    background-color: rgb(136, 32, 41);
}

.reservation-confirmation-rate-menu-container-future {
    display: flex;
    margin-left: 20px;
    margin-top: 27px;
}

.reservation-confirmation-rate-menu-container-past {
    display: flex;
    margin-left: 20px;
    margin-top: 15px;
}

.reservation-confirmation-rate-review-container {
    display: flex;
    height: 80px;
    width: 275px;
    align-items: center;
    margin-left: 10px;
    border-radius: 7px;
    border: .0625rem solid #f1f2f4;
    box-shadow: 0 0.125rem 0.25rem 0 #2d333f33;
}

.reservation-confirmation-rate-review-icon {
    height: 48px;
    width: 48px;
    margin-left: 10px;
}

.reservation-confirmation-browse-menu-icon {
    height: 48px;
    width: 48px;
    margin-left: 10px;
}

.reservation-confirmation-browse-menu-container {
    display: flex;
    height: 80px;
    align-items: center;
    width: 275px;
    margin-left: 15px;
    border-radius: 7px;
    border: .0625rem solid #f1f2f4;
    box-shadow: 0 0.125rem 0.25rem 0 #2d333f33;
}

.reservation-confirmation-rate-review-container:hover {
    cursor: pointer;
    background-color: rgb(252, 252, 252);

}

.reservation-confirmation-rate-review-right {
    margin-left: 5px;
}

.reservation-confirmation-rate-review-text {
    font-size: 1.02rem;
    margin-bottom: 3px;
}

.reservation-confirmation-rate-review-share-text {
    font-size: 0.87rem;
    color: rgb(96, 95, 95);
    font-weight: 300;
}

.reservation-confirmation-browse-menu-text {
    font-size: 1.02rem;
    margin-bottom: 3px;

}

.reservation-confirmation-restaurant-profile-text {
    font-size: 0.87rem;
    color: rgb(96, 95, 95);
    font-weight: 300;
}

.reservation-confirmation-browse-menu-right {
    margin-left: 5px;
}

.reservation-confirmation-browse-menu-container:hover {
    cursor: pointer;
    background-color: rgb(252, 252, 252);
}   

.reservation-confirmation-get-directions-container {
    display: flex;
    height: 80px;
    width: 275px;
    align-items: center;
    margin-left: 10px;
    border-radius: 7px;
    border: .0625rem solid #f1f2f4;
    box-shadow: 0 0.125rem 0.25rem 0 #2d333f33;
}

.reservation-confirmation-get-directions-container:hover {
    cursor: not-allowed;
    background-color: rgb(252, 252, 252);

}

.reservation-confirmation-get-directions-icon {
    height: 48px;
    width: 48px;
    margin-left: 10px;
}

.reservation-confirmation-get-directions-text {
    font-size: 1.02rem;
    margin-left: 3px;
}


