.dining-dashboard-right {
    display: flex;
    flex-direction: column;
    width: 760px;
    margin-left: 70px;
    margin-top: 75px;
    border-color: rgb(181, 179, 179);
    border-radius: 4px;
}

.dining-dashboard-points-container {
    height: 300px;
    background-color: white;
    margin-left: -24px;
    margin-top: -20px;
    border-radius: 4px;
    border-bottom: 0.0625rem solid #d8d9db
}

.dining-dashboard-points-text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 730px;
    height: 70px;
    margin-left: 22px;
    border-bottom: 1.5px solid;
    border-color: rgb(226, 225, 225);
}

.dining-dashboard-points-text2 {
    margin-bottom: 16px;
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 4px;
    color: rgb(50, 50, 50);
}

.dining-dashboard-your-points-text {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 16px;
}

.dining-dashboard-points-graph-icon {
    height: 25px;
    width: 735px;
}

.dining-dashboard-points-middle-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 27px;
    justify-content: space-between;
}

.dining-dashboard-points-middle-left {
    display: flex;
    flex-direction: column;
}

.dining-dashboard-points-graph-container {
    margin-left: 20px;
    margin-top: 10px;
}

.dining-dashboard-points-middle-earned-text {
    font-size: 0.84rem;
    font-weight: 600;
}

.dining-dashboard-points-middle-points {
    margin-top: 2px;
}

.dining-dashboard-0 {
    font-size: 1.1rem;
    color: red;
}

.dining-dashboard-points-PTS {
    color: black;
    font-weight: 300;
}

.dining-dashboard-points-total {
    font-size: 1.05rem;
    color: red;
    font-weight: 400;
}

.dining-dashboard-next-reward-text {
    font-size: 0.83rem;
}

.dining-dashboard-points-PTS2 {
    color: black;
}

.dining-dashboard-points-reward-text {
    border-color: rgb(226, 225, 225);
    margin-right: 40px;
    margin-left: 22px;
    margin-top: 25px;
    margin-bottom: 10px;
}

.dining-dashboard-under-points-border-bottom-line {
    border-bottom: 1.5px solid;
    border-color: rgb(231, 230, 230);
    height: 25px;
    border-width: thin;
}

.dining-dashboard-points-text-space-below {
    margin-bottom: 15px;
    font-size: 1.05rem;
    margin-right: 28px;
    margin-left: 2px;
    margin-bottom: 20x;
    margin-top: 15px;
}

.dining-dashboard-upcoming-reservations-container {
    height: auto;
    margin-top: 20px;
    background-color: white;
    border-radius: 4px;
    padding-bottom: 25px;
    margin-left: -24px;
    padding-top: 10px;
    box-shadow: 0px 1px 1px rgb(217, 217, 217)
}

.dining-dashboard-learn-more-rewards-text {
    font-size: 1rem;
    margin-top: 15px;
    margin-left: 25px;
    color: rgb(37, 127, 158);
    font-weight: 400;
}

.dining-dashboard-upcoming-reservations-details-container {
    display: flex;
    flex-direction: row;
    height: 160px;
}

.dining-dashboard-upcoming-reservations-details-container:hover {
    box-shadow: 1px 0.5px 3px rgb(202, 201, 201);
    cursor: pointer;
}

.dining-dashboard-past-reservations-container {
    height: auto;
    margin-top: 20px;
    background-color: white;
    border-radius: 4px;
    padding-bottom: 25px;
    margin-left: -24px;
    padding-top: 10px;
    box-shadow: 0px 1px 1px rgb(217, 217, 217)

}

.dining-dashboard-past-reservations-rating-container {
    display: flex;
    flex-direction: row;
    margin-left: -4px;
    margin-top: 8px;
}

.dining-dashboard-past-reservations-rating-text {
    color: rgb(0, 0, 0);
    font-weight: 300;
    font-size: 1rem;
}

.dining-dashboard-review-rating-stars {
    margin-left: 5px;
    margin-top: -2px;
}


.dining-dashboard-restaurant-img {
    height: 130px;
    width: 130px;
    border-radius: 3px;
    margin-top: 16px;
    margin-left: 17px;
    object-fit: cover;
}

.reservation-confirmed-icon {
    height: 25px;
    width: 25px;
    margin-top: 3px;
    margin-bottom: -6px;
}

.dining-dashboard-person-icon {
    height: 22px;
    width: 22px;
    margin-bottom: -5px;
}

.dining-dashboard-reservation-completed-icon {
    height: 20px;
    width: 25px;
}

.dining-dashboard-upcoming-reservations-icon {
    height: 32px;
    width: 35px;
    margin-bottom: -10px;
}

.dining-dashboard-upcoming-border {
    border: 1.5px solid;
    border-color: rgb(227, 227, 227);
    height: 160px;
    width: 730px;
    margin-left: 20px;
    margin-top: 13px;
    border-radius: 5px;
}

.dining-dashboard-future-reservation-details {
    margin-top: -3px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    width: 300px;
    padding-left: 20px;
    padding-top: 10px;
}

.dining-dashboard-reservation-confirmed-container {
    margin-left: -3px;
}

.dining-dashboard-past-reservations-details-container {
    display: flex;
    flex-direction: row;
    height: 180px;
}

.dining-dashboard-past-border:hover {
    box-shadow: 1px 0.5px 3px rgb(202, 201, 201);
    cursor: pointer;
}

.dining-dashboard-past-reservation-details {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    width: 300px;
    padding-left: 20px;
    padding-top: 10px;
}

.dining-dashboard-future-details-container {
    margin-top: 13px;
}

.dining-dashboard-past-details-container {
    margin-top: 13px;
}

.dining-dashboard-past-border {
    border: 1.5px solid;
    border-color: rgb(227, 227, 227);
    height: 160px;
    width: 730px;
    margin-left: 20px;
    border-radius: 5px;
    margin-top: 13px;
}

.dining-dashboard-past-header {
    margin-left: 20px;
    font-size: 0.75rem;
    color: rgb(37, 37, 37)
}


.dining-dashboard-future-header {
    margin-left: 20px;
    font-size: 0.75rem;
    color: rgb(37, 37, 37)

}

.dining-dashboard-future-restaurant-name {
    font-size: 1.15rem;
    font-weight: 600;
    margin-bottom: 2px;
}

.dining-dashboard-past-restaurant-name {
    font-size: 1.15rem;
    font-weight: 600;
    margin-bottom: 6px;
    margin-left: -4px;
    width: 500px;
}

.dining-dashboard-reservation-confirmed-text {
    font-size: 1rem;
}

.dining-dashboard-reservation-completed-container {
    margin-left: -9px;
    margin-top: 6px;
}

.dining-dashboard-reservation-completed-align {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dining-dashboard-party-date-container {
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    align-items: center;
    margin-left: -1.5px;
}

.dining-dashboard-party-size-text {
    font-size: 1.1rem;
    color: rgb(94, 94, 94);
    font-weight: 400;
}

.dining-dashboard-reservation-time-text {
    font-size: 1.05rem;
    color: rgb(94, 94, 94);
    font-weight: 400;
    margin-left: -2px;
}

.dining-dashboard-past-reservation-time-align {
    margin-top: 4px;
    margin-left: -7px;
}

.dining-history-none-container {
    margin-left: 20px;
}

.dining-history-none-text {
    font-size: 1rem;
    font-weight: 400;
    color: gray;
}

.dining-dashboard-past-reservation-star {
    font-size: 1.1rem;
    margin-left: 4px;
}

.dining-dashboard-past-leave-review-container {
    margin-left: -4px;
    margin-top: 6px;
}

.dining-dashboard-past-leave-review-text {
    font-weight: 300;
    color: black;
    font-size: 0.98rem;
}



